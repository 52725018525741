.flexiHoursTable {
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.flexiHoursTable * {
  box-sizing: border-box;
}

.infoRow {
  min-height: 26px;
  width: 60%;
  margin-left: 40%;
  font-weight: 600;
  background-color: var(--color-bg-muted);
  border-left: 2px solid var(--color-bg-muted);
  padding: 8px 0 8px 13px;
}

.headerRow {
  font-weight: 600;
}

.name {
  padding-left: 21px;
}

.employeeRow {
  min-height: 26px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--color-bg-muted);
  justify-content: flex-end;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }
}

.employeeRow:hover {
  background-color: var(--color-bg-muted);
}

.textButton {
  all: unset;
  cursor: pointer;
  color: var(--blue-P400);
  padding-right: 8px;
}

.filledButton {
  all: unset;
  cursor: pointer;
  height: 18px;
  padding: 4px 8px;
  background-color: var(--blue-P400);
  color: var(--color-bg-default);
  border-radius: 6px;
  margin-right: 4px;
}

.filledButton:disabled {
  background-color: var(--color-z500);
  cursor: not-allowed;
}

.selectedRow {
  background-color: var(--color-bg-muted);
}

.unlockedMonthRow {
  background-color: var(--color-a900);
}

.unlockedMonthNames {
  padding-left: 24px;
  padding-right: 8px;
  color: var(--ui-support-02);
}

.hoursAndNewBalance {
  display: flex;
  flex-direction: row;
  gap: 4px;
}



.newBalanceValue {
  color: var(--grey-80);
}

.newBalanceText {
  color: var(--grey-80);
  font-size: 10px;
}


.noteAndAdjustment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  button {
    margin-left: auto;
  }
}

.noteAndAdjustment > *:nth-child(1) {
  flex-grow: 1;
  width: auto;
}

.name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chevron {
  display: inline-block;
  content: '';
  width: 14px;
  height: 14px;
  margin-right: 8px;
  padding: 0;
  background-repeat: no-repeat;
  filter: invert(1);
  transform: rotate(-90deg);
}

.showChevron {
  background-image: url('../../../public/collapse.svg');
}

.chevronInverted {
  transform: rotate(90deg);
}

.sortableHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.employeeRow > * {
  padding: 0;
  display: flex;
  min-height: 36px;

  &:nth-child(1) {
    width: 15%;
  }
  &:nth-child(2) {
    width: 10%;
  }
  &:nth-child(3) {
    width: 15%;
  }
  &:nth-last-child(3) {
    width: 15%;
    padding-left: 12px;
    border-left: 2px solid var(--color-bg-muted);
  }
  &:nth-last-child(2) {
    width: 15%;
  }
  &:nth-last-child(1) {
    width: 30%;
  }
}

.inputField {
  display: flex;
  align-items: center;
  padding-right: 8px;

  input {
    all: unset;
    width: 100%;
    height: 16px;
    background-color: var(--color-bg-default);
    border: 1px solid var(--color-z700);
    border-radius: 6px;
    padding: 4px;
    margin: 0;
  }
}

.hoursAndNewBalance input {
  width: 24px;
}

.inputError {
  input {
    border: 1px solid var(--ui-support-02);
  }
}

.monthSummary {
  background: var(--color-accent-default);
  width: 100%;
  box-sizing: border-box;
  top: 0;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  padding-top: 24px;
  padding-bottom: 24px;

  border-bottom: 1px solid var(--color-border-low-contrast);
}

.monthSummary.isPastDue {
  background: var(--color-error-low-contrast);
}

.monthSummary.isPastDue .monthSummaryLockButton {
  background: var(--color-error);
}

.monthSummary.hasUpcomingAbsence {
  background: var(--color-warning-low-contrast);
}

.monthSummary.stickTop {
  position: sticky;
  z-index: 1;
}

.monthSummary.stickBottom {
  position: sticky;
  z-index: 1;
  bottom: 0;
  background: var(--color-warning-low-contrast);
}

.monthSummaryList {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  color: var(--color-text-primary);
  font-weight: 400;
  min-width: 180px;
}

.hoursSummary td {
  padding-right: 8px;
}

/* hoursSummary second column */
.hoursSummary td:nth-child(2) {
  text-align: right;
}

.topPadding td {
  padding-top: 8px;
}

.bottomPadding td {
  padding-bottom: 8px;
}

.boldText {
  font-weight: 600;
}

.absenceCode {
  color: var(--color-p300);
}

.monthSummaryMessage {
  display: none;
  justify-content: center;
  color: var(--color-text-secondary);
}

.monthSummaryLockButtonContainer {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  padding-right: 32px;
}

@media (min-width: 550px) {
  .monthSummaryLockButtonContainer {
    padding-top: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 32px;
  }
}

.monthSummaryLockButton {
  background: var(--color-p300);
  font-size: 14px;
}

.monthSummaryLockButton:disabled {
  color: var(--color-z500);
  background: var(--color-z700);
  border: 1px solid var(--color-z700);
  cursor: auto;
}

.monthSummaryUnlockButton {
  color: var(--color-p300);
  border: 1px solid var(--color-p300);
  background: var(--color-accent-default);
}

@media (min-width: 550px) {
  .monthSummary {
    flex-direction: row;
    align-items: center;
  }
  .monthSummary.stickBottom {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media (min-width: 1200px) {
  .monthSummaryMessage {
    display: flex;
  }
}

.title {
  font-size: 20px;
  padding-bottom: 12px;
}

.text {
  padding: 0 48px;
  text-align: center;
  font-size: 14px;
}

.textProminent {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-primary-body-text);
}

.attentionAnimation {
  animation: glowing 1800ms infinite;
}

@keyframes glowing {
  0% { background-color: var(--color-p300); box-shadow: 0 0 0px ; }
  20% { background-color: var(--color-primary-interactive); box-shadow: 0 0 4px var(--color-p300); }
  60% { background-color: var(--color-p300); box-shadow: 0 0 0px var(--color-p300); }
  100% { background-color: var(--color-p300); box-shadow: 0 0 0px var(--color-p300); }
}

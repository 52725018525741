.flexitimeHolidays {
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.flexitimeHolidays * {
  box-sizing: border-box;
}

.flexitimeHolidaysTable {
  font-size: 16px;
}

.flexitimeHolidaysTable th {
  text-align: left;
}

.flexitimeHolidaysTable tbody tr:hover {
  background-color: var(--color-bg-muted);
}

.flexitimeHolidaysTable td, .flexitimeHolidaysTable th {
  padding: 12px 4px; 
}

.name {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.name span:last-child, .flexitimeHolidaysTable tbody td:not(:first-child) {
  color: var(--grey-80);
}

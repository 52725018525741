#scrollToToday {
  position: absolute;
  display: none;
  width: 120px;
  height: 40px;
  line-height: 36px;
  font-size: 14px;
  letter-spacing: 0.2px;
  background-color: var(--color-primary-interactive);
  color: white;
  text-decoration: none;
  text-align: center;
  z-index: 100;
  border: 0;
  border-radius: 25px;
  top: initial;
  user-select: none;
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.01);
  cursor: pointer;
  outline: none;
}

/* show scroll to today only on reasonable size screens,
   otherwise it takes too much space. This also hides it
   when on-screen keyboard is visible */
@media only screen and (min-height: 400px) {
  #scrollToToday {
    display: block;
  }
}

#scrollToToday.visible {
  bottom: 32px;
  pointer-events: initial;
  animation-name: scrollToToday__slideFromBottom;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

#scrollToToday.hidden {
  display: none;
}

#scrollToToday:active {
  bottom: 30px;
}

/* prevent scroll to today button from overlapping with
   the month summary button */
@media (max-width: 550px) {
  #scrollToToday.visible {
    bottom: 24px;
    right: 24px;
    width: auto;
    padding: 0 20px;
  }
  #scrollToToday:active {
    bottom: 22px;
  }
}

@keyframes scrollToToday__slideFromBottom {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
